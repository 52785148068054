import FAQSec from "../../components/FAQSec/FAQSec";
import { useEffect, useState } from "react";
import { FacebookLogo } from "../../assets/Logos";
import {
  HomePageSubContainerOne,
  OctVidContainer,
} from "../../assets/containers";
import { ButtonFilled, ButtonHollow } from "../../components/Buttons";
import { Link } from "react-router-dom";
import Card from "../../components/Card/Card";
import EvSlider from "../../components/EvSlider/EvSlider";
import PriceCard from "../../components/PriceCardV3/PriceCard";
import SelectPills from "../../components/SelectPill/SelectPill";
import "./Home.css";
import ContactFrom from "../../components/ContactForm/ContactFrom";
import BrandSection from "../../components/BrandSection/BrandSection";
import PriceCardV2 from "../../components/PriceCardV2/PriceCardV2";
import HomeFeaturesSection from "../../components/HomeFeaturesSection/HomeFeaturesSection";
import {
  redirectEvallo,
  redirectEvalloSignup,
  redirectMail,
} from "../../Utils/utils";
import ScrollWatcher from "../../components/ScrollWatcher/ScrollWatcher";
import useScrollAnimation from "../../Hooks/useScrollAnimation";
import VideoPopUp from "../../components/videoPopUp/videoPopUp";
import vidIcon from "./vidThumb.svg";
import PopUp from "../../components/PopUp/PopUp";
import { ToastContainer } from "react-toastify";
import LeadForm from "../../components/LeadForm/LeadForm";
import styles from "../Pricing/pricingV2.module.css";
import getRecommendedPackageWithProducts from "../../Utils/package_recommender";

const Home = () => {
  const [isActive, setIsActive] = useState([
    true,
    false,
    false,
    false,
    false,
    false,
  ]);
  const [selectedPriceIndex, setSelectedPriceIndex] = useState(0);
  const [selectedPriceType, setSelectedPriceType] = useState(0);
  const [selectedValue, setSelectedValue] = useState(0);
  const [vidTrigger, setVidTrigger] = useState(false);
  // const [formTrigger, setFormTrigger] = useState(false);
  const [leadFormTrigger, setLeadFormTrigger] = useState(false);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");

  const [recommendedPlan, setRecommendedPlan] = useState("");
  const [yearly, setYearly] = useState(false);
  const [numberOfTutors, setNumberOfTutors] = useState("6");

  const [monthlySubscriptionData, setMonthlySubscriptionData] = useState([
    {
      type: "Explorer",
      cost: "Free",
      desc: "Experience powerful features for free but with limited capabilities.",
    },
    {
      type: "Starter",
      cost: "10",
      desc: "Ideal for freelance tutors and small businesses.",
    },
    {
      type: "Professional",
      cost: "29",
      desc: "Ideal for small businesses  looking to scale up and take it to the next level.",
    },
    {
      type: "Premium",
      cost: "99",
      desc: "Ideal for larger businesses and enterprises.",
    },
  ]);

  const [yearlySubscriptionData, setYearlySubscriptionData] = useState([
    {
      type: "Explorer",
      cost: "Free",
      desc: "Experience powerful features for free but with limited capabilities.",
    },
    {
      type: "Starter",
      cost: "102",
      desc: "Ideal for freelance tutors and small businesses.",
    },
    {
      type: "Professional",
      cost: "296",
      desc: "Ideal for small businesses  looking to scale up and take it to the next level.",
    },
    {
      type: "Premium",
      cost: "1010",
      desc: "Ideal for larger businesses and enterprises.",
    },
  ]);

  useEffect(() => {
    setIsActive([true, false, false, false, false, false]);
  }, []);

  useScrollAnimation("homeCard", "popIn", 0.5);
  useScrollAnimation("homeSection", "popIn", 1);

  const handleSliderChangeCommitted = (e, value) => {
    switch (value) {
      case 0:
        setSelectedPriceIndex(0);
        break;
      case 40:
        setSelectedPriceIndex(1);
        break;
      case 80:
        setSelectedPriceIndex(2);
        break;
      case 120:
        setSelectedPriceIndex(3);
        break;
    }
  };

  useEffect(() => {
    (async () => {
      try {
        // const productPrices = await getRecommendedPackageWithProducts(numberOfTutors, yearly ? 1 : 0)
        // console.log(productPrices)

        if (numberOfTutors > 0 && numberOfTutors < 3)
          setRecommendedPlan("Starter");
        else if (numberOfTutors >= 3 && numberOfTutors < 13)
          setRecommendedPlan("Professional");
        else setRecommendedPlan("Premium");
      } catch (error) {
        console.log(error);
      }
    })();
  }, [numberOfTutors]);

  const handleFaqClick = (e) => {
    const id = parseInt(e.target.id);
    let _isActive = isActive.map((val, idx) => false);
    console.log("Before", _isActive);
    _isActive[id] = !isActive[id];
    console.log("after", _isActive);
    setIsActive(_isActive);
  };

  return (
    <>
      <div className="container-fluid h-100 d-flex flex-column align-items-center m-0 px-0 overflow-hidden position-relative mt-2">
        <ToastContainer/>
        <VideoPopUp
          trigger={vidTrigger}
          setTrigger={setVidTrigger}
          element={  
                <iframe
                  class="sl-demo"
                  height="80%"
                  width="80%"
                  title="YouTube video player"
                  frameborder="0"
                  src="https://app.storylane.io/demo/iegsn5zdmcx6"
                  name="sl-embed"
                  allow="fullscreen"
                  allowfullscreen
                ></iframe>
              
           
          }
        />
        {leadFormTrigger ? (
          <PopUp trigger={leadFormTrigger} setTrigger={setLeadFormTrigger}>
            <LeadForm
              {...{
                firstName,
                lastName,
                email,
                phone,
                setFirstName,
                setLastName,
                setPhone,
                message,
                setMessage,
                setEmail,
                setFormPopUpTrigger: setLeadFormTrigger,
              }}
            />
          </PopUp>
        ) : (
          <></>
        )}
        <div className="row" id="home">
          <div className="col-12 d-flex flex-column justify-content-center align-items-center home-section-one">
            <div className="home-title-container">
              <div className="home-title text-center mx-3">
                Tutoring Operations are Simplified Here.
              </div>
              <div
                className="home-title-lg text-center"
                style={{ color: "#07151F" }}
              >
                Tutoring Operations
                <br />
                are Simplified Here.
              </div>
            </div>
            <div className="home-description-container">
              <div className="home-description text-center mx-3">
                Evallo makes tutoring businesses more efficient by combining all
                their operational needs in a single, integrated platform.
              </div>
              <span className="home-description-lg text-center">
                Evallo makes tutoring businesses more efficient by combining all{" "}
                <br />
                their operational needs in a single, integrated platform.
              </span>
            </div>
            <div className="d-flex flex-row mt-3 justify-content-around align-items-center home-section-one-actions">
              <ButtonHollow onClick={redirectEvallo}>
                Start for free
              </ButtonHollow>
              <ButtonFilled onClick={(e) => setLeadFormTrigger(true)}>
                Book Demo
              </ButtonFilled>
            </div>
          </div>
        </div>

        <div className="row w-100 mt-5 d-flex justify-content-center align-items-center position-relative  brand-section pt-4">
          <BrandSection />
        </div>

        <div className="row">
          <div className="col-12 d-flex flex-column justify-content-start align-items-center home-section-two homeSection">
            <div className="d-flex flex-column justify-content-start align-items-center home-section-two-sub-one">
              <div className="d-flex justify-content-center align-items-center w-100">
                <span className="line"></span>
                <span className="subheading-container-home">
                  A TUTOR'S LIFE
                </span>
                <span className="line" style={{ transform: "rotate(180deg)" }}>
                  {" "}
                </span>
              </div>
              <div className="home-title-container-tutor">
                <div className="home-title-two text-center d-lg-block d-none">
                Optimize Your <br /> Tutoring Workflow
                </div>
                <div className="home-title-two text-center d-lg-none">
                Optimize Your <br /> Tutoring Workflow
                </div>
              </div>
              <div className="home-description-container-tutor d-flex justify-content-center align-items-center comparison">
                <div className="d-flex justify-content-center align-items-center gap-3 w-100">
                  <span id="current-tutor">Current Problems</span>
                  <span id="vs">vs</span>
                  <span id="evallo-tutor">Evallo's Solutions</span>
                </div>
              </div>
            </div>
            <div className="d-flex flex-column justify-content-start align-items-center home-section-two-sub-two homeCard">
              <div className="d-flex justify-content-center align-items-top features-container fc-one">
                <div className="d-flex flex-column justify-content-start align-items-end features">
                  <div className="features-heading">Too Many Apps</div>
                  <div className="features-description text-end">
                    Too many apps means confused clients <br /> and inefficient
                    onboarding.
                  </div>
                  <img
                    src="/assets/testImages/too_many_apps.png"
                    loading="lazy"
                    className="img-fluid"
                  />
                  {/* <img src='/assets/images/mobile_too_many_apps.svg' className='img-fluid d-lg-none' /> */}
                </div>
                <img src="/assets/images/dividers.svg" className="divider" />
                <div className="d-flex flex-column justify-content-start align-items-start features">
                  <div className="features-heading">Single Sign-On</div>
                  <div className="features-description text-start">
                    Simplify your business with a one-stop dashboard that <br />
                    handles all your tutoring operations with ease!
                  </div>
                  <img
                    src="/assets/testImages/one_stop_shop.png"
                    loading="lazy"
                    className="img-fluid"
                  />
                  {/* <img src='/assets/images/mobile_one_stop_shop.svg' className='img-fluid d-lg-none' /> */}
                </div>
              </div>
            </div>
            <div
              className="d-flex flex-column justify-content-start align-items-center home-section-two-sub-two homeCard"
              style={{ maxWidth: "81vw" }}
            >
              <div className="d-flex justify-content-center align-items-top features-container">
                <div className="d-flex flex-column justify-content-start align-items-end features">
                  <div className="features-heading">Manual Efforts</div>
                  <div className="features-description text-end">
                    Sending homework emails? Calendar invites? Late night
                    <br /> grading? Multiple Excel sheets? Manual Invoices?
                  </div>
                  <img
                    src="/assets/testImages/manual_efforts.png"
                    loading="lazy"
                    className="img-fluid"
                  />
                </div>
                <img src="/assets/images/dividers.svg" className="divider" />
                <div className="d-flex flex-column justify-content-start align-items-start features">
                  <div className="features-heading">Automation, Automation</div>
                  <div className="features-description text-start">
                    Automate your session reminders, homework grading, <br />{" "}
                    session updates, lead management, and more!
                  </div>
                  <img
                    src="/assets/testImages/auto.png"
                    loading="lazy"
                    className="img-fluid"
                  />
                </div>
              </div>
            </div>
            <div
              className="d-flex flex-column justify-content-start align-items-center home-section-two-sub-two homeCard"
              style={{ maxWidth: "81vw" }}
            >
              <div className="d-flex justify-content-center align-items-top features-container">
                <div className="d-flex flex-column justify-content-start align-items-end features">
                  <div className="features-heading">Hunches & Guesswork</div>
                  <div className="features-description text-end">
                    When your systems are not synced, gathering
                    <br /> insights from data becomes difficult.{" "}
                  </div>
                  <img
                    src="/assets/testImages/hunch.png"
                    loading="lazy"
                    className="img-fluid"
                  />
                </div>
                <img src="/assets/images/dividers.svg" className="divider" />
                <div className="d-flex flex-column justify-content-start align-items-start features">
                  <div className="features-heading">Business Intelligence</div>
                  <div className="features-description text-start">
                    With Evallo, you generate critical information about your{" "}
                    <br />
                    business that can help you scale.{" "}
                  </div>
                  <img
                    src="/assets/testImages/bi.png"
                    loading="lazy"
                    className="img-fluid"
                  />
                </div>
              </div>
            </div>
            <div
              className="d-flex flex-column justify-content-start align-items-center home-section-two-sub-two homeCard"
              style={{ maxWidth: "81vw" }}
            >
              <div className="d-flex justify-content-center align-items-top features-container">
                <div className="d-flex flex-column justify-content-start align-items-end features">
                  <div className="features-heading">Lack of Content</div>
                  <div className="features-description text-end">
                    There is no shortage of content, but do you deserve the
                    chaos that <br /> comes with scattered academic material?
                    Absolutely not!{" "}
                  </div>
                  <img
                    src="/assets/testImages/lack.png"
                    loading="lazy"
                    className="img-fluid"
                  />
                </div>
                <img src="/assets/images/dividers.svg" className="divider" />
                <div className="d-flex flex-column justify-content-start align-items-start features">
                  <div className="features-heading">
                    Soooooooo Much Content!
                  </div>
                  <div className="features-description text-start">
                    Evallo provides peer-reviewed practice tests, worksheets,{" "}
                    <br />
                    drills, question banks, and even 3rd party material!
                  </div>
                  <img
                    src="/assets/testImages/too_much.png"
                    loading="lazy"
                    className="img-fluid"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className='col-12 d-flex flex-column justify-content-start align-items-center d-none d-lg-flex home-section-three position-relative p-5'>
                        <div className='subheading-container' style={{ border: "none", borderRadius: "0.8vw" }}>
                            EVALLO SNEAK PEEK
                        </div>
                        <img src='/assets/images/oct.png' className='oct' />
                        <div className='d-flex justify-content-between align-items-center oct-container'>
                            <img src='/assets/images/oct_1.png' />
                            <img src='/assets/images/oct_2.png' />
                            <img src='/assets/images/oct_3.png' />
                        </div>

                    </div>
          <div className="col-12 mb-4 d-lg-none">&nbsp;</div>

          <div className="col-12 d-flex flex-column justify-content-start align-items-center home-section-four position-relative homeSection">
            <div className="d-flex justify-content-center align-items-center w-100">
              <span className="line"></span>
              <span className="subheading-container-home">
                EVALLO SNEAK PEEK
              </span>
              <span className="line" style={{ transform: "rotate(180deg)" }}>
                {" "}
              </span>
            </div>
            <div className="home-container-live d-flex flex-column justify-content-start align-items-center">
              <div className="home-title-container-live">
                <span className="home-title-live">See us live in action</span>
              </div>
              <div className="home-description-container-live">
                <span className="home-description-live text-center">
                  Your unleashed potential fuels ours! <br /> Evallo is designed
                  to help your tutoring business scale.
                </span>
                <span className="home-description-live-lg text-center">
                  Your unleashed potential fuels ours! <br />
                  Evallo is designed to help your tutoring business scale.
                </span>
              </div>
            </div>

            <div
              style={{ position: "relative", cursor: "pointer" }}
              className="homeCard"
            >
              <img
                src="/assets/images/oct_vid_container.png"
                style={{ height: "42vw" }}
              />
              <img
                src={vidIcon}
                style={{
                  height: "34vw",
                  width: "68vw",
                  position: "absolute",
                  top: "4vw",
                  left: "6vw",
                }}
                onClick={(e) => setVidTrigger(true)}
              />
            </div>

            {/* <div>
              <script src="https://js.storylane.io/js/v1/storylane.js"></script>
              <div style={{ position: "relative" }} className="d-none">
                <iframe
                  class="sl-demo"
                  style={{
                    padding: "20px",
                    borderRadius: "45px",
                    border: "4px solid white",
                    boxShadow: "0px 4px 20.600000381469727px 0px #CCE4F6",
                    objectFit: "contain",
                  }}
                  width="352"
                  height="250"
                  title="YouTube video player"
                  frameborder="0"
                  src="https://app.storylane.io/demo/iegsn5zdmcx6"
                  name="sl-embed"
                  allow="fullscreen"
                  allowfullscreen
                ></iframe>
              </div>
            </div> */}
          </div>
          <div className="col-12 d-flex flex-column justify-content-start align-items-center home-section-five position-relative pt-3 homeSection">
            <div className="d-flex justify-content-center align-items-center w-100">
              <span className="line"></span>
              <span className="subheading-container-home">
              EASY TO UNDERSTAND
              </span>
              <span className="line" style={{ transform: "rotate(180deg)" }}>
                {" "}
              </span>
            </div>
            <div className="home-container-of d-flex flex-column justify-content-start align-items-center">
              <div className="home-title-container-of">
                <span className="home-title-of text-center">Our Features</span>
              </div>
              <div className="home-description-container-of">
                <div className="home-description-of">
                  Why is Evallo the best tutoring management tool out there?
                  Well, notice how comprehensive our features are!
                </div>
                <div className="home-description-of-lg">
                  Why is Evallo the best tutoring management tool out there?
                  <br /> Well, notice how comprehensive our features are!
                </div>
              </div>
            </div>

            {/* <div className='row w-100 px-5 mx-5 d-flex justify-content-center align-items-end bg-dark gap-lg-4'>
                            <div className='col-12 col-md-5 col-lg-6 features-wrapper'>
                                <div className='bg-danger our-features-container d-flex flex-column justify-content-between align-items-end flex-lg-row justify-content-lg-center align-items-lg-start ps-2 pt-2 '>
                                    <div className='d-flex w-100 flex-column justify-content-start align-items-start'>
                                        <div className='our-features-heading'>
                                            CRM
                                        </div>
                                        <div className='our-features-description'>
                                            Choose how much time you <br /> want to give per section.
                                        </div>
                                    </div>
                                    <img id="img-crm" src='/assets/testImages/CRM.png' loading="lazy" />
                                </div>

                            </div>
                            <div className='col-12 col-md-7 col-lg-6 features-wrapper '>
                                <div className='our-features-container d-flex flex-column justify-content-start align-items-start p-3 pe-1'>
                                    <div className='our-features-heading'>
                                        Invoicing
                                    </div>
                                    <img id="img-invoice" src='/assets/testImages/invoicing.png' loading="lazy"/>
                                </div>
                            </div>
                        </div> */}

            <div className="row col-gap-3 fc w-100 row-gap-2 homeCard ">
              <div className="col-12 col-md-5 col-lg-6 ">
                <div className="d-flex justify-content-between justify-content-lg-end justify-content-xl-start  align-items-start gap-1 h-100 our-features-container p-2 pe-0 p-lg-4 pe-lg-0">
                  <div className="d-flex flex-column">
                    <div className="our-features-heading">CRM</div>
                    <div className="our-features-description">
                      Manage all your parents, students and tutors with advanced
                      filters and detailed profiles.
                    </div>
                  </div>
                  <div className="w-100 text-end">
                    <img
                      id="img-crm"
                      src="/assets/testImages/CRM.png"
                      className="d-none d-lg-inline-block"
                    />
                    <img
                      id="img-crm"
                      src="/assets/images/CRM_mobile.png"
                      loading="lazy"
                      className="d-lg-none"
                    />
                  </div>
                </div>
              </div>
              <div className="col-12 col-md-7 col-lg-6">
                <div className="d-flex flex-column justify-content-start align-items-start gap-1 h-100 our-features-container p-4">
                  <div className="our-features-heading">Automated Grading</div>
                  <img
                    id="img-invoice"
                    src="/assets/testImages/AutGrade.png"
                    loading="lazy"
                  />
                </div>
              </div>
            </div>

            <div className="row col-gap-lg-3 col-gap-1 fc w-100 row-gap-2 homeCard ">
              <div className="col-6 col-md-3 col-lg-3">
                <div className="d-flex flex-column justify-content-start align-items-start our-features-container gap-1 p-3 pb-0 pt-lg-4 ps-lg-4 h-100 pb-lg-0">
                  <div className="our-features-heading pe-2 me-lg-3 pe-lg-4">
                    Profiles
                  </div>
                  <div className="h-100 d-flex align-items-end pb-0 mb-0">
                    <img
                      id="img-dets"
                      src="/assets/testImages/detailed_profiles.png"
                      loading="lazy"
                      style={{ width: "100%" }}
                    />
                  </div>
                </div>
              </div>
              <div className="col-12 col-md-5 col-lg-6 order-2 order-lg-1">
                <div className="d-flex flex-row justify-content-start gap-lg-1 our-features-container align-items-start p-3 ps-3 p-lg-4 h-100">
                  <div className="d-flex flex-column justify-content-start align-items-start">
                    <div className="our-features-heading">Dashboard</div>
                    <div className="our-features-description">
                      Every user deserves a <br /> unique view based on <br />
                      their priorities.
                    </div>
                  </div>
                  <div className="w-100">
                    <img
                      id="img-dash"
                      src="/assets/testImages/dashboard.png"
                      loading="lazy"
                      style={{ width: "100%" }}
                    />
                  </div>
                </div>
              </div>
              <div className="col-6 col-md-4 col-lg-3 order-1 order-lg-2">
                <div className="d-flex  flex-column justify-content-start justify-content-lg-between align-items-start our-features-container h-100 gap-1 pt-3 ps-3 pb-0 ps-lg-4 pb-lg-0">
                  <div className="our-features-heading">Invoicing</div>
                  <div className="d-flex justify-content-end align-items-end mb-0 w-100">
                    <img
                      id="img-pay"
                      src="/assets/testImages/pay.png"
                      loading="lazy"
                      style={{ width: "100%" }}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="row col-gap-3 w-100 fc row-gap-2 homeCard ">
              <div className="col-12 col-md-5 col-lg-4 ">
                <div className="d-flex h-100 flex-column justify-content-start align-items-start our-features-container p-3 pb-1 gap-3 p-lg-4 pb-lg-1 gap-lg-4">
                  <div className="our-features-heading">Calendar</div>
                  <div className="w-100">
                    <img
                      id="img-cal"
                      src="/assets/testImages/cal.png"
                      loading="lazy"
                      style={{ width: "100%" }}
                    />
                  </div>
                </div>
              </div>

              <div className="col-12 col-md-7 col-lg-4 ">
                <div className="d-flex flex-column h-100 justify-content-start align-items-start our-features-container p-3 gap-4 gap-lg-3 p-lg-4">
                  <div className="our-features-heading">Student Feedback</div>
                  <img
                    id="img-perf"
                    src="/assets/testImages/perf.png"
                    loading="lazy"
                  />
                </div>
              </div>

              <div className="col-12 col-lg-4">
                <div className="d-flex flex-column h-100 justify-content-between align-items-start our-features-container p-3 gap-5 gap-lg-1 pb-lg-4">
                  <div className="d-flex flex-column justify-content-start align-items-start">
                    <div className="our-features-heading">Admin Controls</div>
                    <div className="our-features-description">
                      Your business, your rules! Customized tutoring policies.
                    </div>
                  </div>
                  <div className="w-100">
                    <img
                      id="img-admin"
                      src="/assets/testImages/admin.png"
                      loading="lazy"
                      style={{ width: "100%" }}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="row home-section-seven px-3 px-lg-2 pb-1 mx-lg-5 pe-lg-5 homeSection">
              <div className="col-12">
                <div className="d-flex justify-content-center align-items-center w-100">
                  <span className="line"></span>
                  <span className="subheading-container-home">
                    measure your success
                  </span>
                  <span
                    className="line"
                    style={{ transform: "rotate(180deg)" }}
                  >
                    {" "}
                  </span>
                </div>
              </div>
              <div className="col-12 col-md-4 col-lg-5 d-flex justify-content-center ps-md-5 price-cont">
                <div
                  className="home-container-success d-flex flex-column justify-content-start align-items-center align-items-md-start align-items-lg-start"
                  style={{ gap: "12px" }}
                >
                  <div className="home-title-container-success">
                    <div className="home-title-success text-center text-md-start text-lg-start">
                      We Promise Better Business Outcomes
                    </div>
                  </div>
                  <div className="home-description-container-success ">
                    <div className="home-description-success text-center d-md-none">
                      After conducting hundreds of hours of customer interviews,
                      we have realized that the value added by Evallo goes far
                      beyond money.
                    </div>
                    <div className="home-description-success-lg d-md-block text-start">
                      After conducting hundreds of hours of customer interviews,
                      we have realized that the value added by Evallo goes far
                      beyond money.
                    </div>
                  </div>

                  <ButtonHollow
                    className="bh mt-3"
                    onClick={(e) => setLeadFormTrigger(true)}
                  >
                    Book A Demo
                  </ButtonHollow>
                  <ButtonHollow
                    className="bh-lg"
                    onClick={(e) => {
                      redirectEvallo();
                    }}
                  >
                    Try For Free{" "}
                  </ButtonHollow>
                </div>
              </div>
              <div className="col-md-2 col-lg-1 "></div>
              <div className="col-12 col-md-6 col-lg-6 d-md-flex justify-content-md-center align-items-md-center d-lg-block homeCard cards-prices">
                <div className="row row-gap-2 price-cont">
                  <div
                    className="col-12 d-flex justify-content-start align-items-center ps-4 ps-lg-5 ms-lg-5 mb-md-3"
                    style={{ gap: "20px" }}
                  >
                    <Card heading="72 Hours" desc="Saved Every Month" />
                    <Card heading="3x" desc="Referral Tracking" />
                  </div>
                  <div
                    className="col-12 d-flex justify-content-end align-items-center pe-4"
                    style={{ gap: "20px" }}
                  >
                    <Card heading="$45,000" desc="Annual Savings" />
                    <Card heading="100%" desc="Accurate Reports" />
                  </div>
                </div>
              </div>
            </div>
            <div className="row p-2 homeSection d-none">
              <div className="col-12">
                <div className="d-flex justify-content-center align-items-center w-100">
                  <span className="line"></span>
                  <span className="subheading-container-home">
                    FREE TRIALS! FLAT RATES!
                  </span>
                  <span
                    className="line"
                    style={{ transform: "rotate(180deg)" }}
                  >
                    {" "}
                  </span>
                </div>
              </div>
              <div className="col-12">
                <div
                  className="home-container-price d-flex flex-column justify-content-start align-items-center"
                  style={{ gap: "12px" }}
                >
                  <div className="home-faq-title-container col-12 d-flex flex-column justfy-content-between flex-lg-row justify-content-lg-center align-items-center ">
                    <div className="faq-title-2">Simplified Pricing.</div>
                    <div className="faq-title-1">&nbsp;Unlimited Students.</div>
                  </div>
                  <div className="home-description-container-price d-none">
                    <div className="home-description-price text-center px-3">
                      {" "}
                      Our philosophy is simple - we don’t want to punish you for
                      growing your business. We charge flat rates for four tiers
                      to justify our growing tech costs.
                    </div>
                  </div>
                </div>
              </div>

              {/* Newly Added */}
              <div className={styles.main2_container}>
                <div className={styles.utility_container}>
                  <div className={styles.tutor_input}>
                    <div>Total number of tutors</div>
                    <input
                      type="text"
                      defaultValue={"6"}
                      onChange={(e) => {
                        const regex = /^\d+\.?\d*$/;
                        if (regex.test(e.target.value)) {
                          setNumberOfTutors(parseInt(e.target.value));
                        }
                        // if (e.target.value.trim() === "" || parseInt(e.target.value) === 0) return;
                        // setNumberOfTutors(parseInt(e.target.value))
                      }}
                    />
                  </div>
                  <div className={styles.plan_switch}>
                    <div>Monthly</div>
                    <label className={styles.switch}>
                      <input
                        className={styles.switch_input}
                        type="checkbox"
                        onChange={() => setYearly(!yearly)}
                      />
                      <span className={styles.slider}></span>
                    </label>
                    <div>
                      <div>Yearly</div>
                      <div className={styles.dis_tag}>Save 15%</div>
                    </div>
                  </div>
                </div>
                <div className={styles.card_container}>
                  {!yearly
                    ? monthlySubscriptionData.map((el, i) => {
                        return (
                          <PriceCard
                            planType={el.type}
                            idx={i}
                            cost={el.cost}
                            key={i}
                            recommendedPlan={recommendedPlan}
                            numberOfTutors={numberOfTutors}
                            desc={el.desc}
                            isYearly={yearly}
                            onCTAButtonClick={() => {
                              window.open(
                                "https://app.evallo.org/signup",
                                "_blank"
                              );
                            }}
                          />
                        );
                      })
                    : yearlySubscriptionData.map((el, i) => {
                        return (
                          <PriceCard
                            planType={el.type}
                            idx={i}
                            cost={el.cost}
                            key={i}
                            recommendedPlan={recommendedPlan}
                            numberOfTutors={numberOfTutors}
                            desc={el.desc}
                            isYearly={yearly}
                            onCTAButtonClick={() => {
                              window.open(
                                "https://app.evallo.org/signup",
                                "_blank"
                              );
                            }}
                          />
                        );
                      })}
                </div>

                <Link
                  className={styles.all_feature_btn}
                  to="/pricing#price_comparator_table"
                >
                  <div>Detailed Pricing Breakdown</div>
                  <svg
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M8.4668 1.93359L15.0001 8.00026L8.4668 14.0669"
                      stroke="#0671E0"
                      stroke-width="2"
                      stroke-linecap="square"
                      stroke-linejoin="bevel"
                    />
                    <path
                      d="M1 7.75156H14.0667"
                      stroke="#0671E0"
                      stroke-width="2"
                      stroke-linecap="square"
                      stroke-linejoin="bevel"
                    />
                  </svg>
                </Link>
              </div>

              {/* <div className="d-lg-none col-lg-8 price-select-home d-flex justify-content-center align-items-center">
                <div
                  className="d-flex justify-content-center align-items-center mt-5 pt-2"
                  style={{
                    paddingLeft: "20px",
                    paddingRight: "20px",
                    width: "320px",
                  }}
                >
                  <EvSlider
                    color={"#216A99"}
                    smallFont={true}
                    handleOnChangeCommitted={handleSliderChangeCommitted}
                    selectedValue={selectedValue}
                    selectedPriceType={selectedPriceType}
                  />
                </div>
              </div>
              <div className="d-lg-none col-12 mt-4 pt-1"></div> */}
            </div>
            {/* <div className="row w-100 price-cards-padding"> */}
            {/* <div className="col-12 col-lg-6 col-xxl-3 mt-4">
                                <PriceCardV2 type="Starter" price="$19" tutors="1 tutors" duration="3 Months Free Trial" selected={selectedPriceIndex === 0} setSelected={setSelectedPriceIndex} _id={0} setSelectedValue={setSelectedValue} />
                            </div>
                            <div className='col-12 col-lg-6 col-xxl-3 mt-4'>
                                <PriceCardV2 type="Professional" price="$29" tutors="Upto 10 tutors" duration="2 Months Free Trial" selected={selectedPriceIndex === 1} setSelected={setSelectedPriceIndex} _id={1} setSelectedValue={setSelectedValue} />
                            </div>
                            <div className='col-12 col-lg-6 col-xxl-3 mt-4 '>
                                <PriceCardV2 type="Premium" price="$59" tutors="Upto 30 tutors" duration="14 Days Free Trial" selected={selectedPriceIndex === 2} setSelected={setSelectedPriceIndex} _id={2} setSelectedValue={setSelectedValue} />
                            </div>
                            <div className='col-12 col-lg-6 col-xxl-3 mt-4 '>
                                <PriceCardV2 type="Enterprise" price="$99" tutors="Unlimited tutors" duration="No Free Trial" selected={selectedPriceIndex === 3} setSelected={setSelectedPriceIndex} _id={3} setSelectedValue={setSelectedValue} />
                            </div> */}
            {/* {selectedPriceType === 0
                ? monthlySubscriptionData.map((val, idx) => {
                    return (
                      <div
                        className={`col-12 col-lg-6 col-xl-6 col-xxl-3 mt-4 subs ${
                          selectedPriceType === 0 ? "show1" : null
                        }`}
                      >
                        <PriceCardV2
                          type={val.type}
                          price={val.costPM}
                          tutors={val.config1}
                          duration={val.config2}
                          selected={selectedPriceIndex === idx}
                          setSelected={setSelectedPriceIndex}
                          _id={idx}
                          setSelectedValue={setSelectedValue}
                        />
                      </div>
                    );
                  })
                : annualSubscriptionData.map((val, idx) => {
                    return (
                      <div
                        className={`col-12 col-lg-6 col-xl-6 col-xxl-3 mt-4 exts ${
                          selectedPriceType === 1 ? "show2" : null
                        }`}
                      >
                        <PriceCardV2
                          type={val.type}
                          price={val.costPM}
                          tutors={val.config1}
                          duration={val.config2}
                          selected={selectedPriceIndex === idx}
                          setSelected={setSelectedPriceIndex}
                          _id={idx}
                          setSelectedValue={setSelectedValue}
                        />
                      </div>
                    );
                  })}
            </div> */}
            <div className="row px-4 home-section-nine homeSection">
              <div className="col-12">
                <div className="d-flex justify-content-center align-items-center w-100">
                  <span className="line"></span>
                  <span className="subheading-container-home">FAQs</span>
                  <span
                    className="line"
                    style={{ transform: "rotate(180deg)" }}
                  >
                    {" "}
                  </span>
                </div>
              </div>
              <div className="home-faq-title-container col-12 d-flex flex-column justfy-content-between flex-lg-row justify-content-lg-center align-items-center text-center">
                <div className="faq-title-2">Still Curious?</div>
                <div className="faq-title-1">&nbsp;These might help...</div>
              </div>
              <div className="col-12 mt-5">
                <FAQSec
                  question={
                    "Do you offer unlimited student and parent accounts?"
                  }
                  answer={
                    "Yes, we do! Nothing too complicated here - just go to the CRM and start adding students and parents via the “Bulk Upload” feature or the “Add New User” button. You can also directly share with your clients the unique sign-up link that comes with your account. To access that, simply visit your admin account Settings and click on the “Client Sign-Up” tab, under which you will find a link that students and parents can use to join your organization."
                  }
                  isActive={isActive}
                  buttonId={0}
                  handleFaqClick={handleFaqClick}
                />
              </div>
              <div className="col-12 mt-4">
                <FAQSec
                  question={
                    "Do you have curated content like practice DSAT® or Math drills?"
                  }
                  answer={
                    "Yes, we do! After signing up as a tutoring business administrator, you will have the option to select your subscription type and to add any extensions, which include “Assignments”. You can choose to start your free trial and gain access to hundreds of question banks, worksheets, practice tests, and official answer keys - all available to directly assign to your students."
                  }
                  isActive={isActive}
                  buttonId={1}
                  handleFaqClick={handleFaqClick}
                />
              </div>
              <div className="col-12 mt-4">
                <FAQSec
                  question={"What can I expect when I start using Evallo?"}
                  answer={
                    <>
                      When you start using Evallo to run your tutoring business,
                      you can expect the following:
                      <ul>
                        <li>
                          About 3-4 hours saved daily due to automations in
                          score reporting and invoicing, along with advanced
                          filters to quickly manage your users (students,
                          parents, and tutors) and their schedules.
                        </li>
                        <li>
                          Flexibility in managing your tutoring business
                          policies through the “Organization Defaults” settings.
                        </li>
                        <li>
                          About $45,000 saved yearly (for businesses in the
                          United States) by reducing your Ops team size.
                        </li>
                        <li>
                          Better referral tracking through our Referral Codes
                          feature.
                        </li>
                        <li>
                          100% accurate instant score reports that enhance your
                          client’s service experience.
                        </li>
                        <li>
                          Top-notch customer support with guaranteed response
                          within 24 hours of raising a query
                        </li>
                      </ul>
                    </>
                  }
                  isActive={isActive}
                  buttonId={2}
                  handleFaqClick={handleFaqClick}
                />
              </div>
              <div className="col-12 mt-4">
                <FAQSec
                  question={"What advantage do Evallo’s dashboards offer?"}
                  answer={
                    "Evallo’s CRM functionality allows for detailed customer profiles, aiding in understanding clients better. This, in turn, facilitates personalized tutoring experiences, ultimately enhancing customer interactions."
                  }
                  isActive={isActive}
                  buttonId={3}
                  handleFaqClick={handleFaqClick}
                />
              </div>
              <div className="col-12 mt-4">
                <FAQSec
                  question={
                    "Can Evallo serve individual tutors as well as large-scale tutoring companies?"
                  }
                  answer={
                    "Yes, we can service tutoring businesses of all sizes. We understand that enterprises carry a lot of momentum, so we provide additional support to large businesses in transferring to Evallo. For individual tutors and SMBs, we provide a faster onboarding experience and a complete step-by-step process on how they can use Evallo to scale up."
                  }
                  isActive={isActive}
                  buttonId={4}
                  handleFaqClick={handleFaqClick}
                />
              </div>
              <div className="col-12 mt-4">
                <FAQSec
                  question={
                    "Why is Evallo better than anything else out there?"
                  }
                  answer={
                    <>
                      Evallo is the best tutoring management platform for the
                      following reasons:
                      <ul>
                        <li>
                          Any tutoring business requires three key verticals to
                          run efficiently - Operations, Content, and Marketing.
                          While most platforms are limited to one, Evallo is a
                          comprehensive solution that addresses all three
                          verticals efficiently.{" "}
                        </li>
                        <li>
                          Our user interface is friendly, easy to learn, and
                          scalable.{" "}
                        </li>
                        <li>
                          Our customer support and onboarding tutorials is
                          top-notch.
                        </li>
                        <li>Our platform is fast, secure, and simple.</li>
                        <li>
                          Our pricing is competitive, especially if you consider
                          content.
                        </li>
                      </ul>
                    </>
                  }
                  isActive={isActive}
                  buttonId={5}
                  handleFaqClick={handleFaqClick}
                />
              </div>
              <div className="col-12 mt-4">
                <FAQSec
                  question={"How can I trust my business with Evallo?"}
                  answer={`Evallo was built by tutors, for tutors. If you are facing a pain point right now in running your business, there is a good chance that the founders of Evallo have gone through something similar as well. Thousands of hours of research, hundreds of customer interviews, multiple rounds of peer review, and months of careful development has led to Evallo’s robust offerings. With us, you are guaranteed to receive top-class service, support, and pampering. We are collaborative by nature.`}
                  isActive={isActive}
                  buttonId={6}
                  handleFaqClick={handleFaqClick}
                />
              </div>
            </div>
            <div className="row mt-3 w-100 px-0">
              <div className="col-12 px-0">
                <ContactFrom  {...{
                firstName,
                lastName,
                email,
                phone,
                setFirstName,
                setLastName,
                setPhone,
                message,
                setMessage,
                setEmail,
                queryFor:"sales",
              }} />
              </div>
            </div>
          </div>
        </div>
        <div class="d-flex flex-row justify-content-start align-items-center meteorite-1">
          <div class="meteor"></div>
          <span class="trail"></span>
        </div>

        <div class="d-flex flex-row justify-content-start align-items-center meteorite-2">  
          <div class="meteor"></div>
          <span class="trail"></span>
        </div>

        <div class="d-flex flex-row justify-content-start align-items-center meteorite-3">
          <div class="meteor"></div>
          <span class="trail"></span>
        </div>

        <div class="d-flex flex-row justify-content-start align-items-center  meteorite-4">
          <div class="meteor"></div>
          <span class="trail"></span>
        </div>

        <div class="d-flex flex-row justify-content-start align-items-center meteorite-5">
          <div class="meteor"></div>
          <span class="trail"></span>
        </div>

        <div class="d-flex flex-row justify-content-start align-items-center meteorite-6">
          <div class="meteor"></div>
          <span class="trail"></span>
        </div>

        <div class="d-flex flex-row justify-content-start align-items-center  meteorite-7">
          <div class="meteor"></div>
          <span class="trail"></span>
        </div>

        <div class="d-flex flex-row justify-content-start align-items-center  meteorite-8">
          <div class="meteor"></div>
          <span class="trail"></span>
        </div>
        <div class="d-flex flex-row justify-content-start align-items-center  meteorite-9">
          <div class="meteor"></div>
          <span class="trail"></span>
        </div>

        <div class="d-flex flex-row justify-content-start align-items-center  meteorite-10">
          <div class="meteor"></div>
          <span class="trail"></span>
        </div>
        <div class="d-flex flex-row justify-content-start align-items-center  meteorite-11">
          <div class="meteor"></div>
          <span class="trail"></span>
        </div>

        <div class="d-flex flex-row justify-content-start align-items-center  meteorite-12">
          <div class="meteor"></div>
          <span class="trail"></span>
        </div>
      </div>
    </>
  );
};

export default Home;
